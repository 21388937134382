import styled, { keyframes, css } from 'styled-components';
import {
  Container,
  GradientCircle,
  Paragraph,
  SectionHeader,
  Slider,
} from '@swordhealth/ui-corporate';

// Animations
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(250px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInUpHeader = keyframes`
  0% {
    opacity: 0;
    transform: translateY(85px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const returnSelectedCard = keyframes`
  0% {
    opacity: 0;
    transform: perspective(1100px) rotateY(90deg) scale(1.5);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0) scale(1);
  }
`;

const returnLeftCards = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0);
  }
`;

const returnRightCards = keyframes`
  0% {
    opacity: 0;
    transform: translateX(150%);
  }
  100% {
    opacity: 1;
    transform: translateX(0) rotate(0);
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) =>
    !props.$stacked &&
    css`
      padding: 96px 0 0 0;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding-top: 64px;
      }
    `};

  ${(props) =>
    props.$stacked &&
    css`
      height: 55vh;
      justify-content: flex-end;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding: 0;
      }
    `};
`;

export const GradientBG = styled(GradientCircle)`
  position: absolute;
  max-width: 800px;
  max-height: 300px;
  left: 50%;
  transform: translateX(-50%) translateY(25%) rotateY(180deg);
  opacity: 0.3;

  ${(props) =>
    props.$stacked &&
    css`
      max-height: 350px;
      transform: translateX(-50%) translateY(-25%) rotateY(180deg);
    `};
`;

export const Wrapper = styled.div`
  display: ${(props) => (props.$hideWhenSlider ? 'none' : 'flex')};
  justify-content: center;
  gap: 28px;
  padding-top: 40px;
  opacity: ${(props) => (props.$hasAnimated ? 1 : 0)};

  ${(props) =>
    props.$stacked &&
    css`
      width: 100%;
      position: relative;
      display: flex;
      max-height: 556px;
      padding-top: 64px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        padding-top: 40px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
        padding-top: 32px;
      }
    `};

  &.animate {
    animation: ${fadeInUp} 1000ms ease-in-out;
    animation-delay: 800ms;
    animation-fill-mode: forwards;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: ${(props) => (props.$stacked && !props.$hideWhenSlider ? 'flex' : 'none')};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  min-width: 200px;
  height: 316px;
  border-radius: 32px;
  gap: 16px;
  box-shadow: 0px 12px 40px 0px rgba(69, 86, 105, 0.15);
  cursor: pointer;
  transform: rotate(var(--rotation)) translateX(var(--offset)) translateY(var(--translateY));
  z-index: var(--zindex);
  transition: all 1000ms cubic-bezier(0.31, 0, 0.73, 1);

  &.stacked {
    pointer-events: none;
  }

  &.row {
    position: relative;
    transform: rotate(0deg) translateX(0);

    p,
    img {
      transition: transform 250ms ease-in-out;
    }

    &.shifted-left {
      transform: translateX(-200%) scale(0.75);
      transition: transform 600ms ease;
      pointer-events: none;
    }

    &.shifted-right {
      transform: translateX(200%) scale(0.75);
      transition: transform 600ms ease;
      pointer-events: none;
    }
  }

  &.flipped {
    transform: perspective(1100px) rotateY(65deg) scale(1.5) !important;
    transition: transform 800ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transform-style: preserve-3d;
    transform-origin: center center;
    z-index: 999;
    backface-visibility: hidden;
  }

  /* Modal open states */
  &.offscreen-left {
    opacity: 0;
    transform: translateX(-150%);
    pointer-events: none;
  }

  &.offscreen-right {
    opacity: 0;
    transform: translateX(150%);
    pointer-events: none;
  }

  &.modal-open {
    opacity: 0;
    transform: perspective(1100px) rotateY(45deg) scale(1);
    pointer-events: none;
  }

  /* Return animation states */
  &.returning-selected {
    animation: ${returnSelectedCard} 600ms cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
    pointer-events: none;
    backface-visibility: visible;
  }

  &.returning-left {
    animation: ${returnLeftCards} 600ms cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
    pointer-events: none;
  }

  &.returning-right {
    animation: ${returnRightCards} 600ms cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
    pointer-events: none;
  }
`;

export const CardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 32px;

  &:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: transform 250ms ease-in-out;

    p {
      transform: scale(1.1);
    }

    img {
      transform: scale(1.2);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  padding: 24px;
  gap: 16px;
`;

export const StyledSectionHeader = styled(SectionHeader)`
  width: 100%;
  gap: 24px;
  animation: ${fadeInUpHeader} 1000ms ease-in-out;

  strong {
    padding: 10px 0;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding-top: 32px;
  }
`;

export const SliderWrapper = styled.div`
  width: 100vw;
  max-width: 100vw;
  overflow: visible;
`;

export const StyledSlider = styled(Slider)`
  width: 100%;
  max-width: 100vw;
  overflow: visible;
  pointer-events: auto;

  & .swiper {
    width: 100%;
    max-width: 100vw;
    padding-top: 56px;
    padding-bottom: 48px;
  }

  & .swiper-wrapper {
    width: 100vw !important;
    display: flex;
    align-items: center;
  }

  & .swiper-slide {
    width: auto !important;
    max-width: 200px;
    display: flex;
    justify-content: center;
  }
`;
