import { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';

import { PAIN_CONDITIONS } from './Enums';
import { Paragraph } from '@swordhealth/ui-corporate';
import { CustomAccordion, CustomAccordionItem } from '@/app/_components/shared/Faqs/styles';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 275px;
  min-width: 263px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-left: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: unset;
  }
`;

const List = styled.div`
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-left: 59px;

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: ${(props) => props.theme.colors.grey.dark};
      position: absolute;
      left: 0;
      opacity: 0.2;
    }
  }
`;

const ItemTitle = styled(Paragraph)`
  color: ${(props) => props.theme.colors.grey.default};
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ItemDescription = styled(Paragraph)`
  display: none;
  color: ${(props) => props.theme.colors.grey[900]};
`;

const ItemWrapper = styled.div`
  position: relative;
  max-width: 210px;
  transition: all 200ms ease;
  cursor: pointer;
  padding-bottom: 28px;

  &.open {
    pointer-events: none;

    ${ItemTitle} {
      position: relative;
      color: ${(props) => props.theme.colors.grey[900]};
      font-size: 28px;
      font-weight: ${(props) => props.theme.font.weight.semibold};
      line-height: ${(props) => props.theme.font.body.lineHeight.lg};
      letter-spacing: -0.5px;
      margin-bottom: 8px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        font-size: 25px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        font-size: 20px;
        line-height: 24px;
      }

      @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
        &::before {
          content: '';
          display: block;
          width: 4px;
          height: 100%;
          position: absolute;
          left: -59px;
          background-color: ${(props) => props.theme.colors.grey.dark};
        }
      }
    }

    ${ItemDescription} {
      display: block;
      transition: all 200ms ease;
      height: auto;
    }
  }
`;

export default function PainDescriptions({ id, selectedPain, selectPain, content }) {
  const [expandedItemIndex, setExpandedItemIndex] = useState(null);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  const handleAccordionToggle = (index) => {
    setExpandedItemIndex((currentIndex) => (currentIndex === index ? null : index));
  };

  if (!content || content.length < 1) {
    const fixedContent = {
      0: {
        name: 'Neck',
        description: 'Overcome whiplash, chronic pain, strains and more.',
      },
      1: {
        name: 'Shoulder',
        description: 'Treat frozen shoulder, rotator cuff tendinopathy, dislocations and more.',
      },
      2: {
        name: 'Elbow',
        description: 'Soothe tennis elbow and biceps tendinopathy.',
      },
      3: {
        name: 'Lower back',
        description: 'Get relief from strains, herniated discs, spinal stenosis and more.',
      },
      4: {
        name: 'Hip',
        description:
          'Heal after a hip replacement, treat greater trochanteric pain, bursitis and more.',
      },
      5: {
        name: 'Wrist',
        description: 'Relieve Carpal Tunnel Syndrome, osteoarthritis, tendinopathy and more.',
      },
      6: {
        name: 'Hand',
        description: 'Relieve Carpal Tunnel Syndrome, osteoarthritis, tendinopathy and more.',
      },
      7: {
        name: 'Knee',
        description:
          'Recover after a knee replacement, ease arthritis, meniscal tears, patellofemoral pain and more.',
      },
      8: {
        name: 'Ankle',
        description: 'Remedy fractures, sprains, strains, achilles tendinopathy and more.',
      },
    };

    return (
      <Wrapper>
        <List>
          {PAIN_CONDITIONS.map((pain, index) => {
            return (
              <>
                <ItemWrapper className={cn({ open: index === selectedPain })} key={pain}>
                  <ItemTitle
                    id={`pain-point-description-title-${id}-${index}`}
                    onClick={() => selectPain(index)}
                  >
                    {fixedContent[index].name}
                  </ItemTitle>
                  <ItemDescription id={`pain-point-description-${id}-${index}`}>
                    {fixedContent[index].description}
                  </ItemDescription>
                </ItemWrapper>
              </>
            );
          })}
        </List>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <List>
        {PAIN_CONDITIONS.map((pain, index) => {
          return (
            <>
              {isMobile ? (
                <CustomAccordion>
                  <CustomAccordionItem
                    id={`body-parts-item-${index}`}
                    title={content[index].name}
                    expanded={index === expandedItemIndex}
                    handleClick={() => handleAccordionToggle(index)}
                    $isBodyPartsSection={true}
                  >
                    {content[index].description}
                  </CustomAccordionItem>
                </CustomAccordion>
              ) : (
                <ItemWrapper className={cn({ open: index === selectedPain })} key={pain}>
                  <ItemTitle
                    id={`pain-point-description-title-${id}-${index}`}
                    onClick={() => selectPain(index)}
                  >
                    {content[index].name}
                  </ItemTitle>
                  <ItemDescription id={`pain-point-description-${id}-${index}`}>
                    {content[index].description}
                  </ItemDescription>
                </ItemWrapper>
              )}
            </>
          );
        })}
      </List>
    </Wrapper>
  );
}
