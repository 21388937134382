import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import ArrowIcon from '@/public/icons/arrow-top.svg';
import { Heading, Paragraph } from '@swordhealth/ui-corporate';
import { MarkdownRichText } from '@/app/_components/shared';

export default function List({ list, id }) {
  return (
    <Grid>
      {list?.map((stats, index) => (
        <Card key={stats.title}>
          <Heading
            as="h4"
            size="2xl"
            weight="regular"
            id={`stats-title-${id}-${index}`}
            gradientBoldText={false}
          >
            {stats.arrow === 'Up' && <ArrowUpIcon id={`stats-arrow-up-${id}-${index}`} />}

            {stats.arrow === 'Down' && <ArrowDownIcon id={`stats-arrow-down-${id}-${index}`} />}

            <MarkdownRichText allowedElements={['strong']} unwrapDisallowed>
              {stats.title}
            </MarkdownRichText>
          </Heading>

          <Paragraph size="sm" weight="regular" id={`stats-description-${id}-${index}`}>
            <ReactMarkdown allowedElements={['strong']} unwrapDisallowed>
              {stats.description}
            </ReactMarkdown>
          </Paragraph>
        </Card>
      ))}
    </Grid>
  );
}

export const ArrowUpIcon = styled(ArrowIcon)`
  margin-right: 8px;
`;

export const ArrowDownIcon = styled(ArrowIcon)`
  margin-right: 8px;
  transform: rotateX(180deg);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  justify-content: center;
  align-items: center;
  padding: 48px 40px;
  background-color: ${(props) => props.theme.colors.white};
`;
