import Image from 'next/image';
import { useContext } from 'react';

import { Container, Heading } from '@swordhealth/ui-corporate';
import {
  ButtonWrapper,
  Content,
  Inner,
  Subtitle,
  Wrapper,
  ExtraLabel,
  UpperLabel,
  ImageWrapper,
} from './styles';

import Section from '@/components/ui/Section';
import getImgSrcWorkaround from '@/utils/images';
import EnrollButton from '@/app/components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import { formattedTitle } from '@/utils/formatted-title';

const Hero = ({ content, userName }) => {
  const { openModal, client } = useContext(ClientContext);
  const { buttonLabel, subtitle, title, label, upperLabel, media } = content;

  return (
    <Section>
      <Wrapper>
        <Container>
          <Inner>
            <Content>
              {upperLabel && <UpperLabel>{upperLabel}</UpperLabel>}
              <Heading as="h1" size="3xl" weight="bold">
                {formattedTitle({ title, userName })}
              </Heading>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              <ButtonWrapper>
                <div>
                  <EnrollButton
                    id="header_button"
                    isActive={client.isActive}
                    buttonLocation="header"
                    openModal={() =>
                      openModal({ buttonText: buttonLabel, buttonLocation: 'header' })
                    }
                    client={client}
                    buttonLabel={buttonLabel}
                    product={client?.product}
                    redirectToApp={client?.redirectToApp}
                  />
                </div>
                {label && <ExtraLabel>{label}</ExtraLabel>}
              </ButtonWrapper>
            </Content>

            <ImageWrapper>
              <Image
                id="hero_image"
                src={getImgSrcWorkaround(media?.url ?? media?.data?.attributes?.url)}
                alt={media?.alternativeText ?? media?.data?.attributes?.alternativeText ?? ''}
                objectFit="contain"
                width={644}
                height={440}
              />
            </ImageWrapper>
          </Inner>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Hero;
