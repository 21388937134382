import Image from 'next/image';
import { useContext, useEffect, useState } from 'react';

import { MarkdownRichText } from '@/app/components/shared';
import { Container } from '@swordhealth/ui-corporate';
import {
  StyledParagraph,
  StyledSectionHeader,
  StyledContainer,
  ButtonWrapper,
  Wrapper,
  Card,
  GradientBG,
  StyledSlider,
  SliderWrapper,
  CardInner,
  CardFace,
} from './styles';
import EnrollButton from '@/app/_components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import VideoModal from './VideoModal/VideoModal';

import getImgSrcWorkaround from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { useInView } from 'react-intersection-observer';

const ThematicCardsSection = ({ content }) => {
  const { openModal, client } = useContext(ClientContext);
  const [scroll, setScroll] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [cardData, setCardData] = useState();
  const [isFlipped, setIsFlipped] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [cardReturning, setCardReturning] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);

  const [ref, inView] = useInView({
    threshold: 1,
    rootMargin: '50% 0% 0% 0%',
  });

  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);
  const isTablet = useBetterMediaQuery(
    `(min-width: ${theme.breakpoints.min_md}px) and (max-width: ${theme.breakpoints.max_md}px)`,
  );
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  const {
    sectionHeader: { title, upperLabel, description, titleSize },
    buttonLabel,
    disclaimer,
    cards,
  } = content;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (inView) {
        setScroll(true);
        setHasAnimated(true);
      }
      if (scrollPosition === 0) {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [inView, scroll, hasAnimated]);

  const handleCardClick = (card, index) => {
    setIsFlipped(index);
    setSelectedCardIndex(index);
    setTimeout(() => {
      setCardData(card);
      setOpenVideoModal(true);
    }, 150);
  };

  const handleCloseModal = () => {
    setCardReturning(true);

    setTimeout(() => {
      setIsFlipped(false);
      setCardReturning(false);
      setOpenVideoModal(false);
      setSelectedCardIndex(null);
    }, 600);
  };

  const getCardClassName = (index) => {
    let classNames = '';

    if (cardReturning) {
      // During card return animation
      if (index === selectedCardIndex) {
        classNames += 'returning-selected';
      } else if (index < selectedCardIndex) {
        classNames += 'returning-left';
      } else {
        classNames += 'returning-right';
      }
    } else if (openVideoModal) {
      // While modal is open
      if (index === selectedCardIndex) {
        classNames += 'flipped modal-open';
      } else if (index < selectedCardIndex) {
        classNames += 'offscreen-left';
      } else {
        classNames += 'offscreen-right';
      }
    } else if (isFlipped === index) {
      // During initial flip
      classNames += 'flipped';
    } else if (isFlipped !== false) {
      // Other cards during initial flip
      if (index < isFlipped) {
        classNames += 'shifted-left';
      } else {
        classNames += 'shifted-right';
      }
    }
    // Default positioning classes
    classNames += scroll ? ' row' : ' stacked';

    return classNames;
  };

  return (
    <>
      <StyledContainer xAlign="center" $stacked={!scroll} ref={ref}>
        <GradientBG $stacked={!scroll} />
        {scroll && (
          <Container>
            <StyledSectionHeader
              title={<MarkdownRichText elements={['strong', 'bold']}>{title}</MarkdownRichText>}
              label={upperLabel}
              description={description ?? ''}
              xAlign="center"
              titleSeoLevel="h1"
              titleSize={titleSize}
            />
          </Container>
        )}

        <Wrapper
          $stacked={!scroll || !isDesktop}
          $hideWhenSlider={(isMobile || isTablet) && scroll}
          $hasAnimated={hasAnimated}
          className={!hasAnimated ? 'animate' : ''}
        >
          {cards.map((card, index) => {
            const middle = Math.floor(cards.length / 2);
            const offset = index - middle;

            const positioningValues = [
              {
                rotation: -30,
                verticalOffset: 316 * 0.75,
                horizontalOffset: 185,
              },
              {
                rotation: -12,
                verticalOffset: 316 * 0.25,
                horizontalOffset: 120,
              },
              {
                rotation: 0,
                verticalOffset: 0,
                horizontalOffset: 0,
              },
              {
                rotation: 12,
                verticalOffset: 316 * 0.25,
                horizontalOffset: -120,
              },
              {
                rotation: 30,
                verticalOffset: 316 * 0.75,
                horizontalOffset: -185,
              },
            ];
            const cardPositioning = positioningValues[index];
            const rotation = cardPositioning.rotation;
            const horizontalOffset = cardPositioning.horizontalOffset * 0.66;
            const verticalOffset = cardPositioning.verticalOffset;
            const zIndex = index === middle ? 5 : cards.length - Math.abs(offset);
            return (
              <>
                <Card
                  key={index}
                  className={getCardClassName(index)}
                  style={{
                    '--rotation': `${rotation}deg`,
                    '--offset': `${horizontalOffset}px`,
                    '--translateY': `${verticalOffset}px`,
                    '--zindex': zIndex,
                  }}
                  onClick={() => handleCardClick(card, index)}
                >
                  <CardInner>
                    <CardFace>
                      <Image
                        id="thematic_cards_image"
                        src={getImgSrcWorkaround(
                          card.image?.url ?? card.image?.data?.attributes?.url,
                        )}
                        alt={
                          card.image?.alternativeText ??
                          card.image?.data?.attributes?.alternativeText ??
                          ''
                        }
                        objectFit="contain"
                        width={114}
                        height={135}
                      />
                      <StyledParagraph>{card.text}</StyledParagraph>
                    </CardFace>
                  </CardInner>
                </Card>
              </>
            );
          })}
        </Wrapper>
      </StyledContainer>
      {(isMobile || isTablet) && scroll && (
        <SliderWrapper>
          <StyledSlider
            loop
            pagination
            centeredSlides
            initialSlide={2}
            spaceBetween={30}
            slidesPerView={isTablet ? 3 : 'auto'}
            slidesOffsetBefore={isTablet ? 85 : 0}
            slidesOffsetAfter={isTablet ? 85 : 0}
            slides={[
              ...cards.map((card, index) => (
                <>
                  <Card
                    key={index}
                    className={getCardClassName(index)}
                    onClick={() => handleCardClick(card, index)}
                  >
                    <CardInner>
                      <CardFace>
                        <Image
                          id="thematic_cards_image"
                          src={getImgSrcWorkaround(
                            card.image?.url ?? card.image?.data?.attributes?.url,
                          )}
                          alt={
                            card.image?.alternativeText ??
                            card.image?.data?.attributes?.alternativeText ??
                            ''
                          }
                          objectFit="contain"
                          width={114}
                          height={135}
                        />

                        <StyledParagraph>{card.text}</StyledParagraph>
                      </CardFace>
                    </CardInner>
                  </Card>
                </>
              )),
            ]}
          />
        </SliderWrapper>
      )}
      {scroll && (
        <ButtonWrapper>
          <EnrollButton
            id="thematic_cards_button"
            isActive={client.isActive}
            buttonLocation="thematic_cards"
            openModal={() =>
              openModal({ buttonText: buttonLabel, buttonLocation: 'thematic_cards' })
            }
            client={client}
            buttonLabel={buttonLabel}
            product={client?.product}
            redirectToApp={client?.redirectToApp}
          />
          {disclaimer && <StyledParagraph>{disclaimer}</StyledParagraph>}
        </ButtonWrapper>
      )}
      {openVideoModal && (
        <VideoModal
          cardData={{ ...cardData, buttonLabel: buttonLabel }}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

ThematicCardsSection.defaultConfig = {
  rounded: false,
  colored: false,
  spaceBottom: true,
  spaceTop: false,
  marginBottom: false,
  withGradient: true,
};

export default ThematicCardsSection;
