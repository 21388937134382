import { useCallback, useContext, useRef, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import { MarkdownRichText } from '../../../shared';
import ClientContext from '@/contexts/client.context';
import EnrollButton from '@/app/_components/core/EnrollButton';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import useVideoTracking from '@/utils/useVideoTracking';
import useOnClickOutside from '@/utils/useOnClickOutside';
import theme from '@/utils/styles-variables';

import {
  Wrapper,
  CloseButton,
  Container,
  Gradient,
  IconClose,
  VideoContainer,
  ButtonWrapper,
  Button,
  Video,
  ContentWrapper,
  StyledSectionHeader,
} from './styles';
import PlaySVG from '@/public/icons/ic_play_fill.svg';
import PauseSVG from '@/public/icons/ic_pause_fill.svg';

const VideoModal = ({ cardData, onClose }) => {
  const { openModal, client } = useContext(ClientContext);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const {
    text,
    buttonLabel,
    videoButtonLabel,
    video,
    videoMobile,
    videoURLMobile,
    videoURL,
    videoDescription,
    videoTitle,
    videoTitleSize,
    videoUpperLabel,
    xAlign,
  } = cardData;

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const encodedEmotion = btoa(text);
  const videoRef = useRef(null);
  const modalRef = useRef(null);
  const videoMobileFinalURL = videoMobile?.data?.attributes?.url ?? videoURLMobile;
  const videoFinalURL = video.data?.attributes?.url ?? videoURL;
  const { onVideoExit, ...trackVideo } = useVideoTracking({
    src: isMobile ? videoMobileFinalURL : videoFinalURL,
  });

  const handlePlayVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setVideoPlaying(true);
    }
  };

  const handlePauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setVideoPlaying(false);
    }
  };

  const closeModal = useCallback(() => {
    setIsClosing(true);

    setTimeout(() => {
      onVideoExit();
      onClose();
    }, 600);
  }, [onVideoExit, onClose]);

  // Custom hook to close modal when clicking outside
  useOnClickOutside(modalRef, closeModal);

  return (
    <RemoveScroll enabled removeScrollBar={false}>
      <Wrapper $isClosing={isClosing}>
        <Gradient $isClosing={isClosing} />
        <Container ref={modalRef} $isClosing={isClosing}>
          <CloseButton type="button" onClick={closeModal}>
            <IconClose alt="close" src="/icons/circle-close.svg" width="20" height="20" />
          </CloseButton>
          <ContentWrapper>
            <StyledSectionHeader
              label={videoUpperLabel ?? ''}
              title={
                <MarkdownRichText elements={['strong', 'bold']}>{videoTitle}</MarkdownRichText>
              }
              description={videoDescription ?? ''}
              xAlign={xAlign ?? 'center'}
              videoTitleSize={videoTitleSize ?? '2xl'}
            />
            <VideoContainer
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Video id="video-media" ref={videoRef} {...trackVideo} playsinline preload="metadata">
                <source
                  src={isMobile ? videoMobileFinalURL + '#t=0.1' : videoFinalURL + '#t=0.1'}
                  type="video/mp4"
                />
              </Video>
              {!videoPlaying && (
                <Button id="video-button" variant="rounded" size="md" onClick={handlePlayVideo}>
                  <PlaySVG />
                </Button>
              )}
              {videoPlaying && isHovered && (
                <Button id="video-button" variant="rounded" size="md" onClick={handlePauseVideo}>
                  <PauseSVG />
                </Button>
              )}
            </VideoContainer>
            <ButtonWrapper>
              <EnrollButton
                id="thematic_video_modal_button"
                isActive={client.isActive}
                buttonLocation="thematic_video_modal"
                openModal={() =>
                  openModal({
                    buttonText: videoButtonLabel ?? buttonLabel,
                    buttonLocation: 'thematic_video_modal',
                    conditionLabel: encodedEmotion,
                  })
                }
                client={client}
                buttonLabel={videoButtonLabel ?? buttonLabel}
                product={client?.product}
                redirectToApp={client?.redirectToApp}
                conditionLabel={encodedEmotion}
              />
            </ButtonWrapper>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </RemoveScroll>
  );
};

export default VideoModal;
