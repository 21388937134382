import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 405px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    min-width: 400px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    width: calc(50% - 20px);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
`;
