import Image from 'next/image';
import { useContext, useEffect, useState, useMemo } from 'react';

import {
  ButtonWrapper,
  ImageWrapper,
  StyledParagraph,
  StyledSectionHeader,
  GradientBG,
  StyledContainer,
  LogosWrapper,
  EnrollButtonWrapper,
  FixedWrapper,
  SimpleHeroGradient,
} from './styles';
import getImgSrcWorkaround from '@/utils/images';
import EnrollButton from '@/app/_components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import { Logos } from '@/app/components/shared/Logos/Logos';
import { RichText } from '@swordhealth/ui-corporate';
import HeroAnimation from './animation/Animation';
import Portal from '@/utils/create-portal';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import { useInView } from 'react-intersection-observer';
import { formattedTitle } from '@/utils/formatted-title';

const HeroThematic = ({ content, isReferral = false, referralUser }) => {
  const { openModal, client } = useContext(ClientContext);
  const [isVisible, setIsVisible] = useState(false);
  const {
    sectionHeader: { title, upperLabel, description, titleSize, xAlign = 'center' },
    buttonLabel,
    disclaimer,
    animation = true,
    image,
    showLogos,
  } = content;

  useEffect(() => {
    const overlay = document.getElementById('overlaySection');
    if (!overlay) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    function verifyOverlay() {
      const overlay = document.getElementById('overlaySection');
      if (!overlay || overlay.classList.contains('overlayIsClosed')) {
        setIsVisible(true);
      }
    }
    window.addEventListener('animationend', verifyOverlay);

    return () => {
      window.removeEventListener('animationend', verifyOverlay);
    };
  }, [isVisible]);

  const logos = useMemo(() => {
    const logoData = client.logo?.data || client.logos?.data;
    if (!logoData) return [];
    return Array.isArray(logoData) ? logoData.slice(0, 3) : [logoData];
  }, [client?.logo, client?.logos]);

  const enrollButton = (isFixed = false) => (
    <EnrollButton
      id={isFixed ? 'fixed_header_button' : 'header_button'}
      isActive={client.isActive}
      buttonLocation="header"
      openModal={() => openModal({ buttonText: buttonLabel, buttonLocation: 'header' })}
      client={client}
      buttonLabel={buttonLabel}
      product={client?.product}
      redirectToApp={client?.redirectToApp}
    />
  );

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);
  const [ref, inView, entry] = useInView({
    threshold: 1,
    initialInView: true,
    rootMargin: '-10px',
  });

  const shouldShowFixedButton =
    isMobile && !inView && !!client.clientKey && entry?.boundingClientRect?.top < 100;

  return (
    <>
      {isReferral && <HeroAnimation className="vertical" />}
      <StyledContainer xAlign="center">
        {showLogos && (
          <LogosWrapper>
            <div>
              {client.clientKey ? (
                <Logos
                  logos={logos}
                  centered
                  showSwordLogo={client.showSwordLogo}
                  client={client}
                  withBackground
                />
              ) : (
                <Logos logos={[]} centered showSwordLogo client={client} withBackground />
              )}
            </div>
          </LogosWrapper>
        )}

        <StyledSectionHeader
          title={
            <RichText elements={['strong', 'br']}>
              {formattedTitle({ title, referralUser })}
            </RichText>
          }
          label={upperLabel}
          description={description ?? ''}
          xAlign={xAlign}
          titleSize={titleSize}
          titleSeoLevel="h1"
          animate={isVisible && animation}
          notAnimate={!animation}
        />

        <ButtonWrapper ref={ref} animate={isVisible && animation} notAnimate={!animation}>
          {enrollButton()}
          {disclaimer && <StyledParagraph>{disclaimer}</StyledParagraph>}
        </ButtonWrapper>

        {isReferral && (
          <Portal portalId="sticky-enroll-button-portal">
            <FixedWrapper $visible={shouldShowFixedButton}>
              <EnrollButtonWrapper>{enrollButton(true)}</EnrollButtonWrapper>
            </FixedWrapper>
          </Portal>
        )}

        {image?.data ? (
          <ImageWrapper
            notAnimate={!animation}
            animate={isVisible && animation}
            $isReferral={isReferral}
          >
            {!isReferral && <GradientBG />}
            <Image
              id="thematic_hero_image"
              src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
              alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
              objectFit="contain"
              width={624}
              height={392}
              priority
            />
          </ImageWrapper>
        ) : (
          <SimpleHeroGradient />
        )}
      </StyledContainer>
    </>
  );
};

HeroThematic.defaultConfig = {
  rounded: false,
  colored: true,
  spaceBottom: true,
  spaceTop: true,
  marginBottom: false,
};

export default HeroThematic;
