import styled from 'styled-components';

import { Heading } from '@swordhealth/ui-corporate';

const Quote = ({ id, quote, customerName, companyName }) => {
  return (
    <Wrapper>
      <Heading
        as="p"
        size="xl"
        weight="bold"
        align="left"
        id={`quote-text-${id}`}
        data-testid="quote-info"
      >
        {quote}
      </Heading>
      <Name id={`quote-customer-name-${id}`} data-testid="quote-customer">
        {customerName}
      </Name>
      {companyName && (
        <Company id={`quote-company-name-${id}`} data-testid="quote-company">
          {companyName}
        </Company>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 72px 48px;
  flex: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 24px;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-top: 25px;
  }
`;

const Company = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export default Quote;
