import styled from 'styled-components';

export const Animation = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 26%;
  top: 0;
  clip-path: circle(farthest-side at 50% -4000px);
  background-color: var(--section-bg);
  overflow: clip;
  transform: translateZ(-1px);
  pointer-events: none;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      var(--ui-colors-neutral-default) 0%,
      rgb(from var(--ui-colors-neutral-default) r g b / 0%) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) =>
    props.horizontal &&
    `
    clip-path: none;
    bottom: 0;
    transform: translateX(-50%);
    max-width: var(--container-max-width);
    left: 50%;
    width: 100%;
    overflow: initial;
    
    &::after {
      display: none;
    }
  `}
`;

export const LayerOne = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    160deg,
    rgb(from var(--ui-colors-negative-default) r g b / 0%) 48%,
    rgb(from var(--ui-colors-negative-default) r g b / 20%) 65%,
    rgb(from #d0cfe4 r g b / 100%) 80%,
    rgb(from #d0cfe4 r g b / 100%) 100%
  );
  width: 100%;
  height: 100%;
  opacity: 0.6;

  ${(props) =>
    props.horizontal &&
    `
    --gradient-width: 200px;
    right: inherit;
    height: initial;
    top: initial;
    bottom: calc(var(--section-padding-vertical) * 1.3);
    left: calc(var(--section-padding-vertical) * -1);
    
    @media (min-width: 768px) {
      --gradient-width: 320px;
    }
    
    @media (min-width: 1024px) {
      --gradient-width: 420px;
      bottom: calc(var(--section-padding-vertical) * 0.9);
    }
  `}
`;

export const LayerTwo = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    193deg,
    rgb(from var(--ui-colors-negative-default) r g b / 0%) 48%,
    rgb(from var(--ui-colors-negative-default) r g b / 20%) 55%,
    rgb(from #d0cfe4 r g b / 100%) 80%,
    rgb(from #d0cfe4 r g b / 90%) 100%
  );
  width: 100%;
  height: 100%;
  opacity: 1;

  ${(props) =>
    props.horizontal &&
    `
    display: none;
  `}
`;

export const LayerThree = styled.div`
  position: absolute;
  background: radial-gradient(
    circle,
    rgb(from #d0cfe4 r g b / 100%) 0%,
    rgb(from var(--ui-colors-white) r g b / 0%) 40%,
    rgb(from var(--ui-colors-white) r g b / 0%) 100%
  );
  width: 170%;
  height: 170%;
  top: 0;
  left: 0;
  transform: translate3d(-46%, -15%, -1px);
  border-radius: 100%;

  @media (prefers-reduced-motion: no-preference) and (min-width: 768px) {
    animation: layerAnimation 15s infinite linear;
  }

  @keyframes layerAnimation {
    0% {
      transform: translate3d(-46%, -15%, -1px);
    }
    20% {
      transform: translate3d(-6%, -15%, -1px);
    }
    40% {
      transform: translate3d(-38%, -10%, -1px);
    }
    60% {
      transform: translate3d(-1%, -5%, -1px);
    }
    80% {
      transform: translate3d(-6%, 10%, -1px);
    }
    100% {
      transform: translate3d(-46%, -15%, -1px);
    }
  }

  ${(props) =>
    props.horizontal &&
    `
    width: 80%;
    height: 100%;
    background: radial-gradient(
      circle,
      rgb(from #d0cfe4 r g b / 35%) 0%,
      rgb(from var(--ui-colors-white) r g b / 0%) 40%,
      rgb(from var(--ui-colors-white) r g b / 0%) 100%
    );
    transform: translate3d(-30%, 8%, -1px);
    
    @media (prefers-reduced-motion: no-preference) and (min-width: 768px) {
      animation: layerAnimationHorizontal 15s infinite linear;
    }
    
    @keyframes layerAnimationHorizontal {
      0% {
        transform: translate3d(-30%, 8%, -1px);
      }
      20% {
        transform: translate3d(60%, 15%, -1px);
      }
      40% {
        transform: translate3d(-40%, 10%, -1px);
      }
      60% {
        transform: translate3d(-30%, -35%, -1px);
      }
      80% {
        transform: translate3d(50%, -20%, -1px);
      }
      100% {
        transform: translate3d(-30%, 8%, -1px);
      }
    }
    
    &::after {
      content: '';
      width: 70%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: radial-gradient(
        circle,
        rgb(from #ff8283 r g b / 8%) 0%,
        rgb(from #d0cfe4 r g b / 0%) 40%,
        rgb(from #d0cfe4 r g b / 0%) 100%
      );
    }
  `}
`;
