import { Wrapper, Content, Description } from './styles';

import { Heading } from '@swordhealth/ui-corporate';

const Icon = ({ title, label }) => {
  return (
    <Wrapper>
      <Content>
        <Heading size="xl" weight="bold" as="h3">
          {title}
        </Heading>
        <Description>{label}</Description>
      </Content>
    </Wrapper>
  );
};

export default Icon;
