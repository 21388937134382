import { CardGrid as CorporateCardGrid } from '@swordhealth/ui-corporate';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

const transformCardList = (cardList) =>
  cardList.map(
    ({
      title,
      description,
      button_label,
      button_href,
      image,
      icon,
      background,
      imageFit,
      xAlign,
      variant,
    }) => {
      const imageData = getImgData(image);
      return {
        title,
        description,
        textLink: {
          href: button_href,
          label: button_label,
        },
        image: {
          url: getImgSrcWorkaround(imageData?.url || ''),
          alternativeText: imageData?.alternativeText || '',
        },
        icon: icon,
        imageFit,
        xAlign,
        background,
        variant,
      };
    },
  );

const CardGrid = ({ content, cardType }) => {
  const {
    cardHero,
    backgroundGradient,
    buttonGroupPosition,
    cardList,
    iconCardList,
    columnCount,
    id,
    layout,
    config,
  } = content;

  const selectedCardList = useMemo(
    () => (cardType === 'icon' ? iconCardList : cardList) || [],
    [cardType, cardList, iconCardList],
  );
  const transformedCardList = useMemo(
    () => transformCardList(selectedCardList),
    [selectedCardList],
  );

  return (
    <Wrapper $spaceBottom={config?.spaceBottom}>
      <CorporateCardGrid
        id={`card-grid-section-${id}`}
        sectionHeader={{
          label: cardHero?.upperLabel,
          xAlign: 'center',
          titleSize: cardHero?.titleSize ?? '2xl',
          title: cardHero?.title,
          description: cardHero?.description,
        }}
        as="div"
        cardList={transformedCardList}
        backgroundGradient={backgroundGradient}
        buttonGroupPosition={buttonGroupPosition}
        columnCount={columnCount}
        layout={layout}
        cardType={cardType}
        paddingBottom={false}
      />
    </Wrapper>
  );
};

CardGrid.defaultConfig = {
  rounded: true,
  colored: true,
  spaceBottom: true,
  spaceTop: false,
  marginBottom: true,
};

export default CardGrid;
const Wrapper = styled.div`
  & > div:first-of-type {
    padding-top: 0 !important;
    background-color: transparent !important;
  }
  ${(props) =>
    props.$spaceBottom === false &&
    css`
      padding-bottom: 40px;
    `};
`;

export const IconCardGrid = (props) => <CardGrid {...props} cardType="icon" />;
IconCardGrid.defaultConfig = CardGrid.defaultConfig;

export const DefaultCardGrid = (props) => <CardGrid {...props} cardType="default" />;
DefaultCardGrid.defaultConfig = CardGrid.defaultConfig;
