import styled, { keyframes } from 'styled-components';
import { GradientCircle, SectionHeader, Button as UiButton } from '@swordhealth/ui-corporate';
import Close from '@/public/icons/circle-close.svg';

// Animations
const fadeInUp = keyframes`
  from {  
    opacity: 0;
    transform: translateY(50vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0.9;
    transform: perspective(1100px) rotateY(-90deg) scale(1.5);
  }
  to {
    opacity: 1;
    transform: perspective(1100px) rotateY(0deg) scale(1);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 1;
    transform: perspective(1100px) rotateY(0deg) scale(1);
  }
  70% {
    transform: perspective(1100px) rotateY(-75deg) scale(1.1);
  }
  100% {
    opacity: 0;
    transform: perspective(1100px) rotateY(-90deg) scale(0.5);
  }
`;

const fadeBackgroundColor = keyframes`
  0% {
    background-color: ${(props) => props.theme.colors.white};
  }
  100% {
    background-color: transparent; 
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: ${(props) => props.theme.colors.white};
  animation: ${(props) => (props.$isClosing ? fadeBackgroundColor : 'none')} 600ms ease-out forwards;

  @media (max-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-top: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: 954px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 12px 40px 0px rgba(0, 0, 0, 0.05);
  z-index: 1500;
  padding: 64px 120px 0 120px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation: ${(props) => (props.$isClosing ? slideOut : slideIn)} 800ms ease-out forwards;
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.min_xXl}px) {
    max-width: 1088px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.min_md}px) {
    max-width: 688px;
    padding: 64px 64px 0 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 56px 24px 0 24px;
    border-radius: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding: 40px 24px 0 24px;
    border-radius: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  opacity: 0;
  animation: ${fadeInUp} 1000ms ease-in-out;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  flex: 1;
`;

export const StyledSectionHeader = styled(SectionHeader)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const Gradient = styled(GradientCircle)`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  animation: ${(props) => props.$isClosing && fadeOut} 800ms ease-in-out forwards;
`;

export const CloseButton = styled.button`
  position: fixed;
  top: 24px;
  right: 24px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.grey[800]};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 32px;
    height: 32px;
    top: 16px;
    right: 16px;
  }
`;

export const IconClose = styled(Close)`
  width: 16px;
  height: 16px;
  & > path {
    fill: ${(props) => props.theme.colors.grey[800]};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    width: 20px;
    height: 20px;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 48px;
  border: 10px solid ${(props) => props.theme.colors.white};
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(31, 34, 44, 0.7) inset, 0px 12px 40px rgba(0, 0, 0, 0.05);
  aspect-ratio: 2/1;
  z-index: 3000;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-height: 290px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    min-height: 272px;
    max-height: 312px;
    margin-top: 24px;
  }
`;

export const Video = styled.video`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Button = styled(UiButton)`
  display: inline-block;
  position: absolute;
  top: calc(50% - 20px);
  right: calc(50% - 20px);
  padding: 0;
  --button-svg-size: unset;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 100%;
    justify-content: end;
    flex-direction: column;
    padding-bottom: 22px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    position: absolute;
    padding-top: 0;
    padding-bottom: 24px;

    button {
      z-index: 3001;
    }
  }
`;
