import React from 'react';
import { Animation, LayerOne, LayerTwo, LayerThree } from './styles';

const HeroAnimation = () => {
  return (
    <Animation data-testid="animation">
      <LayerOne />
      <LayerTwo />
      <LayerThree />
    </Animation>
  );
};

export default HeroAnimation;
